.progress-bar {
  background: linear-gradient(45deg, #06c, turquoise);
}

/* .control .progress-bar {
  background: linear-gradient(45deg, white, white);
} */
.control .progress {
  /* background: linear-gradient(45deg, transparent, #06c, turquoise); */
 background:  linear-gradient(45deg, white, white);
}

.pointer-executive:hover {
  cursor: pointer;
}

.iconDetails {
  float: center;
  height: 20px;
  width: 30px;
}

.used-budget {
  font-size: 18px;
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(1, 98, 189, 1) 20%,
    rgba(21, 156, 182, 1) 92%,
    rgba(28, 220, 194, 1) 100%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-executive h4 {
  font-size: 20px;
  text-align: left;
}

.profile-image {
  height: 120px;
  width: 120px;
  margin-left: -20px;
  margin-top: -30px;
}

.pm-entity-image {
  height: 80px;
  width: 80px;
  margin-left: 5px;
  margin-top: -5px;
}
