.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.custom-shape-divider-bottom-1627305719 {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1627305719 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
  transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1627305719 .shape-fill {
  fill: #ffffff;
}

.detail-gradient-background {
  background: rgb(2, 104, 189);
  background: linear-gradient(
    90deg,
    rgba(2, 104, 189, 1) 0%,
    rgba(25, 210, 193, 1) 100%
  );
}

.section-gradient-background {
  background: rgb(4, 105, 188);
  background: linear-gradient(
    180deg,
    rgba(4, 105, 188, 1) 0%,
    rgba(15, 139, 183, 1) 100%
  );
}

.bg-dark-blue {
  background-color: #003569;
}

.custom-shape-divider-top-1627317072 {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1627317072 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 99px;
}

.custom-shape-divider-top-1627317072 .shape-fill {
  fill: #ffffff;
}

.custom-shape-divider-top-1627316642 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1627316642 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 99px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1627316642 .shape-fill {
  fill: #ffffaf;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.info-gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 20%,
    rgba(1, 98, 189, 1) 20%,
    rgba(21, 156, 182, 1) 92%,
    rgba(28, 220, 194, 1) 100%
  );
}

.component-spinner {
  background-color: rgba(255, 255, 255, 0.4);
  display: block;
  overflow: hidden;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}
.component-spinner--block {
  position: relative;
  top: auto;
  left: auto;
  min-height: 100px;
  background-color: transparent;
}
.component-spinner--block .loading {
  margin-top: 0;
  left: calc(50% - 35px);
  top: calc(50% - 27px);
}